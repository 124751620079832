/* eslint-disable */
import * as bootstrap from 'bootstrap';

export default {
  finalize() {
    const nativeLightbox = document.getElementById("imageLightbox");
    const lightboxImage = document.getElementById("lightboxImage");
    const lightboxCaption = document.getElementById("lightboxCaption");

    if (!nativeLightbox || !lightboxImage || !lightboxCaption) {
      console.error("Lightbox elements not found.");
      return;
    }

    let currentIndex = -1;
    let images = [];

    // Get all images with the 'lightbox-trigger' class within '.add-lightbox' containers
    const lightboxTriggers = document.querySelectorAll(".add-lightbox .lightbox-trigger");

    // Collect the images and set up event listeners
    lightboxTriggers.forEach((trigger, index) => {
      images.push({
        src: trigger.dataset.lightboxSrc || trigger.getAttribute("src"),
        alt: trigger.dataset.lightboxAlt || trigger.getAttribute("alt") || "Lightbox Image",
        caption: trigger.dataset.lightboxCaption || trigger.getAttribute("alt") || "",
      });

      // When an image is clicked, open the lightbox
      trigger.addEventListener("click", function (event) {
        event.preventDefault();
        currentIndex = index;
        openNativeLightbox();
      });
    });

    // Create the modal instance once and reuse it
    const modalNativeLightbox = new bootstrap.Modal(nativeLightbox);

    // Open the lightbox and display the current image
    function openNativeLightbox() {
      if (currentIndex >= 0 && currentIndex < images.length) {
        lightboxImage.src = images[currentIndex].src;
        lightboxImage.alt = images[currentIndex].alt;
        lightboxCaption.textContent = images[currentIndex].caption;

        // Show the modal
        modalNativeLightbox.show();
      }
    }

    // Navigate to the next image
    function nextImage() {
      currentIndex = (currentIndex + 1) % images.length; // Loop back to first image
      openNativeLightbox();
    }

    // Navigate to the previous image
    function prevImage() {
      currentIndex = (currentIndex - 1 + images.length) % images.length; // Loop to last image
      openNativeLightbox();
    }

    // Set up event listeners for next/prev buttons
    const nextBtn = document.getElementById("nextImage");
    const prevBtn = document.getElementById("prevImage");

    if (nextBtn) {
      nextBtn.addEventListener("click", nextImage);
    }

    if (prevBtn) {
      prevBtn.addEventListener("click", prevImage);
    }

    // Keyboard navigation (Arrow keys)
    document.addEventListener("keydown", function (event) {
      if (event.key === "ArrowRight") {
        nextImage();
      } else if (event.key === "ArrowLeft") {
        prevImage();
      }
    });

    // Touch swipe navigation for mobile
    let touchStartX = 0;
    let touchEndX = 0;

    nativeLightbox.addEventListener("touchstart", function (event) {
      touchStartX = event.changedTouches[0].screenX;
    }, { passive: true });

    nativeLightbox.addEventListener("touchend", function (event) {
      touchEndX = event.changedTouches[0].screenX;
      if (touchEndX < touchStartX) {
        nextImage();
      } else if (touchEndX > touchStartX) {
        prevImage();
      }
    }, { passive: true });

    // Close modal reset and cleanup
    nativeLightbox.addEventListener('hidden.bs.modal', function () {
      currentIndex = -1; // Reset the index when the modal is closed
      lightboxImage.src = ''; // Clear the image
      lightboxCaption.textContent = ''; // Clear the caption

      // Cleanup backdrops if necessary
      const backdrops = document.querySelectorAll('.modal-backdrop');
      backdrops.forEach((backdrop) => {
        backdrop.remove();
      });
    });
  },
};
